.add-quiz {
  width: 100%;
}

.inline-fields {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  div {
    margin-right: 20px;
  }
}

.mb-5{
  margin-bottom: 5rem;
}
.mr-btn{
  margin-right: 1rem;
  margin-bottom:6px;
}