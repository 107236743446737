.full-width {
  width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

.number-widget {
  border-left: 0.3rem solid #212529;
}

.scorer-widget {
  border-left: 0.3rem solid #212529;
  border-right: 0.3rem solid #212529;
}

.sidebar-backdrop.fade {
  z-index: 0;
}

.show-help {
  position: absolute;
  right: 1rem;
  color: #fff;
  background-color: #212529;
  padding: 0px 8px;
  border-radius: 30px;
}

.dataDelete h6 {
  margin-bottom: 1rem;
}

.dataDelete {
  margin-top: 3rem;
}

.page-item.selected > .page-link {
  background-color: #212529;
  color: #fff;
}

.page-item > .page-link {
  color: #212529;
}

form .border-end-0:focus-within,
form .border-end-0:focus,
form .border-end-0:focus-visible,
form .border-end-0 {
  z-index: 0 !important;
}

.form-select:disabled {
  background-color: #e9ecef;
  opacity: 0.8;
}

.rounded.img-thumbnail {
  background-color: #e9ecef;
}

.copy-icon:hover {
  color: #212529;
  cursor: pointer;
}

