body {
  background-color: #e5e5e5;
}

.account-initial {
  background-color: #223354;
  font-size: 20px !important;
  // border-radius: 100%;
  // width: 5px !important;
  // height: 5px !important;
}

.dropdown-toggle::after {
display: none;
}
